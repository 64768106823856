import BaseService from "./BaseService";
import { API_BASE_URL_ENV } from "../jsx/common/common";
import http from './HttpService'

const apiEndpoint = API_BASE_URL_ENV() + "/crm/project";
const apiGetEndpoint = API_BASE_URL_ENV() + "/crm/projects";

export default class CRMProjectService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getList(params) {
    return http.get(apiGetEndpoint, {params});
    // if(params){
    // } else {
    //   return http.get(apiGetEndpoint);
    // }
  }
}