import BaseService from "./BaseService";
import { API_BASE_URL_ENV } from "../jsx/common/common";
import http from './HttpService'

const apiEndpoint = API_BASE_URL_ENV() + "/crm/lead";
const apiBulkEndpoint = API_BASE_URL_ENV() + "/crm/sendCRMEmail";
const apiChartEndpoint = API_BASE_URL_ENV() + "/crm/CRMChart";

export default class CRMLeadService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  bulkEmails(data) {
    return http.post(apiBulkEndpoint, data);
  }

  getChart() {
    return http.get(apiChartEndpoint);
  }
}