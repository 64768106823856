import React from "react";
import ReactApexChart from "react-apexcharts";

const OrderSummaryChart = ({data}) => {
    const getPercentage = (num) => {
      return (num / data?.allLead) * 100
    }
    const state = {
        series: [
          getPercentage(data?.newLead), 
          getPercentage(data?.onProgressLead), 
          getPercentage(data?.successLead), 
          getPercentage(data?.closedLead)
        ],
        options: {
          labels: ["New", "In Progress", "Success", 'Closed'],
          colors: ["#214BB8", "#45ADDA", "#07bc0c", "#e74c3c"],
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "40%",
              },
            },
          },
          stroke: {
            width: "5",
          },
          dataLabels: {
            formatter(val, opts) {
              return [val.toFixed() + "%"];
            },
            dropShadow: {
              enabled: false,
            },
            style: {
              fontSize: "13px",
              colors: ["#fff"],
            },
          }
        },
    }
      return (
        <ReactApexChart options={state.options} series={state.series} type="donut" />
      )

}

export default OrderSummaryChart;
