import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";

const CardItem = ({item, setItem, index, setAddModal, selectedLeads, setSelectedLeads}) =>{
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>
                    <input 
                        type='checkbox' 
                        id={`ID-${index}`} 
                        checked={!!selectedLeads?.filter(res => res?.id === item?.id)?.length}
                        onChange={e=> {
                            if(e.target.checked){
                                setSelectedLeads([...selectedLeads, item])
                            } else {
                                setSelectedLeads(selectedLeads?.filter(res => item?.id !== res?.id))
                            }
                        }} 
                    />
                    <label for={`ID-${index}`} className="mx-2">{item.id}</label>
                </strong>
            </td>
            <td>{item?.company_name}</td>
            <td>{item?.company_phone}</td>
            <td>{item?.company_email}</td>
            <td>{item?.client_name}</td>
            <td>{item?.client_phone}</td>

            <td>
                {isExist("add_crm") && <Dropdown>
                    <Dropdown.Toggle className="light sharp i-false">
                        <i className="la la-ellipsis-h" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> {
                            setSelectedLeads([item])
                            setItem(item)
                            setAddModal(true)
                        }}>{Translate[lang]?.send_email}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </td>
            </tr>
    )
}
export default CardItem;