export const customRules= [
  {
    label: "admin",
    rules:[
      {label: "Add", value: 'add_admin'},
      {label: "Edit", value: 'edit_admin'},
      {label: "View", value: 'view_admin'},
      {label: "Delete", value: 'delete_admin'},
    ]
  },
  {
    label: "assets",
    rules:[
      {label: "Add", value: 'add_assets'},
      {label: "Edit", value: 'edit_assets'},
      {label: "View", value: 'view_assets'},
      {label: "Delete", value: 'delete_assets'},
    ]
  },
  {
    label: "employees",
    rules:[
      {label: "Add", value: 'add_employees'},
      {label: "Edit", value: 'edit_employees'},
      {label: "View", value: 'view_employees'},
      {label: "Delete", value: 'delete_employees'},
    ]
  },
  {
    label: "departments",
    rules:[
      {label: "Add", value: 'add_departments'},
      {label: "Edit", value: 'edit_departments'},
      {label: "View", value: 'view_departments'},
      {label: "Delete", value: 'delete_departments'},
    ]
  },
  {
    label: "allowances",
    rules:[
      {label: "Add", value: 'add_allowances'},
      {label: "Edit", value: 'edit_allowances'},
      {label: "View", value: 'view_allowances'},
      {label: "Delete", value: 'delete_allowances'},
    ]
  },
  {
    label: "deduction",
    rules:[
      {label: "Add", value: 'add_deduction'},
      {label: "Edit", value: 'edit_deduction'},
      {label: "View", value: 'view_deduction'},
      {label: "Delete", value: 'delete_deduction'},
    ]
  },
  {
    label: "vacations",
    rules:[
      {label: "Add", value: 'add_vacations'},
      {label: "Edit", value: 'edit_vacations'},
      {label: "View", value: 'view_vacations'},
      {label: "Delete", value: 'delete_vacations'},
    ]
  },
  {
    label: "organization",
    rules:[
      {label: "Add", value: 'add_organization'},
      {label: "Edit", value: 'edit_organization'},
      {label: "View", value: 'view_organization'},
      {label: "Delete", value: 'delete_organization'},
    ]
  },
  {
    label: "crm",
    rules:[
      {label: "Add", value: 'add_crm'},
      {label: "Edit", value: 'edit_crm'},
      {label: "View", value: 'view_crm'},
      {label: "Delete", value: 'delete_crm'},
    ]
  },
  {
    label: "provision",
    rules:[
      {label: "View", value: 'view_provision'},
    ]
  },
  {
    label: "permissions",
    rules:[
      {label: "Edit", value: 'edit_rules'},
      {label: "View", value: 'view_rules'},
    ]
  },
  {
    label: "sick_leave",
    rules:[
      {label: "Add", value: 'add_sick_leave'},
      {label: "View", value: 'view_sick_leave'},
    ]
  },
  {
    label: "salaries",
    rules:[
      {label: "Total", value: 'total_salaries'},
      {label: "View", value: 'view_salaries'},
    ]
  },
]