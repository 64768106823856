import { useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import DeleteModal from "../../../../common/DeleteModal";
import ChangeStatusModal from "../ChangeStatusModal";
import CRMProjectService from "../../../../../services/CRMProjectService";

const CardItem = ({item, setItem, index, setResignationModal, setAddModal, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const [type, setType] = useState('')
    const [status, setStatus] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const cRMProjectService = new CRMProjectService()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.name}
            </td>
            <td>
                {item?.client_name}
            </td>
            <td>{item?.client_phone}</td>
            <td>{item?.client_email}</td>
            <td>{item?.date?.split('T')[0]}</td>
            <td>{item?.employee?.name}</td>
            <td>
                <Badge
                    style={{
                        textTransform: 'capitalize'
                    }}
                    variant={item?.pay === "full" ? 'outline-primary' : item?.pay === 'in_advance' ? 'outline-warning' : item?.pay === 'installment' ? 'outline-success' : 'outline-secondary'}>
                    {item?.pay?.replaceAll('_', ' ')}
                </Badge>
            </td>
            <td style={{textTransform: 'capitalize'}}>{item?.type}</td>
            <td>
                <Badge
                    style={{textTransform: 'capitalize'}}
                    variant={item?.stage === "on_progress" ? 'primary' : item?.stage === 'on_hold' ? 'warning' : item?.stage === 'completed' ? 'success' : 'secondary'}>
                    {item?.stage === 'on_progress' ? 'In Progress' : item?.stage?.replaceAll('_', ' ')}
                </Badge>
            </td>
            <td>
                {item.crm_project_attachments?.length > 0 ? item.crm_project_attachments?.map((att=>(
                    <a href={att.url} target='_black' rel="noreferrer">
                        <i className="la la-file-pdf" style={{fontSize: '2.5rem'}}></i>
                    </a>
                ))) : '-'}
            </td>
            <td>
                {(isExist("edit_crm") && isExist("delete_crm")) && <Dropdown>
                    <Dropdown.Toggle className="light sharp i-false">
                        <i className="la la-ellipsis-h" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isExist("edit_crm") && <Dropdown.Item onClick={()=> {
                            setItem(item)
                            setAddModal(true)
                        }}> {Translate[lang]?.edit}</Dropdown.Item>}
                        {isExist("delete_crm") && <Dropdown.Item onClick={()=> {
                            setDeleteModal(true)
                            setType('delete')
                        }}>{Translate[lang]?.delete}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </td>
            {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={item?.client_name}
                      deletedItem={item}
                      modelService={cRMProjectService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                      type={type}
                    />}
            {status && <ChangeStatusModal
                addModal={status} 
                setAddModal={()=> setStatus(false)} 
                item={item} 
                setShouldUpdate={setShouldUpdate}
            />}
            </tr>
    )
}
export default CardItem;