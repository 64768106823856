import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import OrderSummaryChart from "./OrderSummaryChart";
import CRMLeadService from "../../../../services/CRMLeadsService";
import MonthDropDown, { months } from "../../../Enums/MonthDropDown";
import '../style.css'

const SalesChart = () => {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState({})
  const [formData, setFormData] = useState({
    month: "",
    year: 0
  })

  useEffect(()=>{
    new CRMLeadService().getChart().then(res => {
      if(res?.status){
        setData(res?.data?.data)
        setFormData({
          year: res?.data?.data[0].year, 
          month: {
            label: months[new Date().getMonth()],
            value: months[new Date().getMonth()]
          }
        })
      }
    })
  }, [])

  useEffect(()=> {
    let getObj = data?.find(res => res?.month === formData?.month?.value)
    setSelected(getObj)
  }, [formData?.month])

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4}>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="m-0">{formData?.year}</p>
                <div className="monthDropDown">
                  <MonthDropDown params={formData} changeParams={e => setFormData({...formData, month: e})} />
                </div>
              </div>
              {!!selected?.allLead && <div id="donutChart">
                <OrderSummaryChart data={selected} />
              </div>}
              <div className="d-flex justify-content-between mt-4">
                  <div className="text-center">
                    <svg 
                      width={25} height={10} viewBox="0 0 25 10" 
                      fill="none" xmlns="http://www.w3.org/2000/svg" 
                    >
                      <rect width={25} height={10} rx={4} fill="#214BB8" />
                    </svg>
                    <h4 className="fs-18 text-black mb-1 font-w600">{selected?.newLead}</h4>
                    <span className="fs-14">New</span>
                  </div>
                  <div className="text-center">
                    <svg
                      width={25} height={10} viewBox="0 0 25 10" 
                      fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width={25} height={10} rx={4} fill="#45ADDA" />
                    </svg>
                    <h4 className="fs-18 text-black mb-1 font-w600">{selected?.onProgressLead}</h4>
                    <span className="fs-14">In Progress</span>
                  </div>
                  <div className="text-center">
                    <svg
                      width={25} height={10} viewBox="0 0 25 10"
                      fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width={25} height={10} rx={4} fill="#07bc0c" />
                    </svg>
                    <h4 className="fs-18 text-black mb-1 font-w600">{selected?.successLead}</h4>
                    <span className="fs-14">Success</span>
                  </div>
                  <div className="text-center">
                    <svg
                      width={25} height={10} viewBox="0 0 25 10"
                      fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width={25} height={10} rx={4} fill="#e74c3c" />
                    </svg>
                    <h4 className="fs-18 text-black mb-1 font-w600">{selected?.closedLead}</h4>
                    <span className="fs-14">Closed</span>
                  </div>
              </div>
            </Col>
          </Row>
        </Card.Body >
      </Card>
    </Fragment>
  );
};

export default SalesChart;