import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import { AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import Loader from "../../../../common/Loader";
import uploadImg from '../../../../../images/upload-img.png';
import BaseService from "../../../../../services/BaseService";
import CRMLeadService from "../../../../../services/CRMLeadsService";

const AddBulkEmailModal = ({addModal, setAddModal, selectedLeads, setSelectedLeads})=>{
    const [formData, setFormData] = useState({
        message: '',
        attachments: ""
    })
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth.lang)

    const fileHandler = (e) => {
        let files = e.target.files
        const filesData = Object.values(files)

        if (filesData?.length) {
            setLoading(true)
            new BaseService().postUpload(filesData[0]).then(res=>{
                if(res?.status === 200){
                    setFormData({...formData, attachments: res?.data?.url})
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    }

    const submit = (e) =>{
        e.preventDefault();
        let data ={
            emails: selectedLeads?.map(res=> {
                return {
                    attachment: formData?.attachments,
                    message: formData?.message,
                    email: res?.company_email
                }
            })
        }

        new CRMLeadService().bulkEmails(data)?.then(res=>{
            if(res && res?.status){
                toast.success('Email Send Successfully')
                setSelectedLeads([])
                setAddModal()
            }
        })
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{Translate[lang]?.bulk_email}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <label>{Translate[lang]?.message}</label>
                            <textarea
                                name='message'
                                className="w-100"
                                style={{
                                    minHeight: '150px',
                                    maxHeight: '240px',
                                    padding: '8px',
                                    borderColor: '#dedede',
                                    borderRadius: '4px'
                                }}
                                placeholder={Translate[lang]?.message}
                                onChange={(e) => setFormData({...formData, message: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className='mt-3'>
                            <div className='form-group w-100'>
                                <label className="m-0">{Translate[lang]?.attachments}</label>
                                <div className="image-placeholder">	
                                    <div className="avatar-edit">
                                        <input type="file" onChange={(e) => fileHandler(e)} accept="image/*" id={`imageUpload1`} /> 					
                                        <label htmlFor={`imageUpload1`}  name=''></label>
                                    </div>
                                    <div className="avatar-preview2 m-auto">
                                        <div id={`imagePreview`}>
                                        {!loading && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {loading && <Loader />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {!!formData?.attachments && <Col md={6} className='d-flex align-items-center justify-content-center'>
                            <a href={formData?.attachments} target='_black' rel="noreferrer">
                                <i className="la la-file-pdf" style={{fontSize: '10rem'}}></i>
                            </a>
                        </Col>}
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{Translate[lang]?.send}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddBulkEmailModal;