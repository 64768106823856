import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import { AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import CRMLeadService from "../../../../../services/CRMLeadsService";

const ChangeStatusModal = ({addModal, setAddModal, item, setShouldUpdate})=>{
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const cRMLeadService = new CRMLeadService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(() => {
        setStatus(item?.status)
    },[item])

    const submit = (e) =>{
        e.preventDefault();
        let data = { 
            status: status
        }
        setLoading(true)
        cRMLeadService.update(item?.id, data)?.then(res=>{
            if(res && res?.status === 200){
                toast.success('Status Updated Successfully')
                setShouldUpdate(prev=> !prev)
                setAddModal()
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{Translate[lang]?.status}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <label>
                                <input type='radio' name='status' checked={status === 'on Progress'} onChange={() => setStatus('on Progress')} />
                                <span className="mx-3">In Progress</span>
                            </label>
                        </Col>
                        <Col md={12}>
                            <label>
                                <input type='radio' name='status' checked={status === 'new'} onChange={() => setStatus('new')} />
                                <span className="mx-3">New</span>
                            </label>
                        </Col>
                        <Col md={12}>
                            <label>
                                <input type='radio' name='status' checked={status === 'success'} onChange={() => setStatus('success')} />
                                <span className="mx-3">Success</span>
                            </label>
                        </Col>
                        <Col md={12}>
                            <label>
                                <input type='radio' name='status' checked={status === 'closed'} onChange={() => setStatus('closed')} />
                                <span className="mx-3">Closed</span>
                            </label>
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setAddModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default ChangeStatusModal;